import { ROOT, REPORT_ADVANCED } from "@/api/constant";
import { handleResponse } from "@/api/handle-response";
import { requestOptions } from "@/api/request-options";

function getReportAdvanced(data) {
  return fetch(ROOT + REPORT_ADVANCED, requestOptions.post(data))
    .then(handleResponse)
    .then((data) => data);
}

export const reportApi = {
  getReportAdvanced,
};
